export const invUploadConfig = {
  'price-range': {
    min: 20000,
    diff: 20000
  },

  'no-of-prev-owners': ['first-owner', 'second-owner', 'third-owner', 'fourth-owner', 'fifth-owner'],

  'usage-type': [
    { title: "dry-land", value: "dry_land" },
    { title: "wet-land", value: "wet_land" },
    { title: "haulage", value: "haulage" },
    { title: "commercial", value: "commercial" }],

  'manufacture-min-yr': 1960


};
